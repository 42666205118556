import React, { useEffect, useState } from "react";

const PrivacyEN = props => {
  const [value, setValue] = useState();
  useEffect(() => {});
  return (
    <div className="privacyCont">
      <div>
        <h1>Privacy & Cookie Policy</h1>
        <br />
        <strong>Premise</strong>
        <br />
        This Policy illustrates how Bottegone del Vino SA processes your
        Personal Data and how cookies work when you use our APP tracking for
        COVID-19 containment. The document can be modified at our discretion at
        any time, its latest version is available whenever you access our APP.{" "}
        <br />
        <br />
        <strong>1. Owner and manager of data processing</strong>
        <br />
        Bottegone del Vino SA <br />
        Via Magatti 3 - 6900 Lugano, Ticino (Switzerland)
        <br />
        Email: bottegonelugano@gmail.com <br />
        <br />
        <strong>2. Data collection and processing</strong>
        <br />
        The Personal Data collected on a voluntary basis by filling out the
        online tracking form are:
        <br />
        name and surname
        <br />
        phone number
        <br />
        address
        <br />
        <br />
        Your Personal Data is processed exclusively to fulfil the obligations
        under the Swiss Directive for the containment of COVID-19, and is
        processed on the basis of your consent on a voluntary basis.
        <br />
        <br />
        The data collected may - where strictly necessary - be stored outside
        Switzerland, in compliance with the principle of transparency according
        to the Federal Commissioner for Data Protection and Information (FDPIC),
        the Federal Council, or on the basis of contractual clauses relating to
        EU rules for the transmission of personal data to third countries.
        <br />
        <br />
        <strong>3. Data recipients</strong>
        <br />
        Your Personal Data may be communicated exclusively to the competent
        Swiss cantonal authorities who will make an explicit request to
        Bottegone del Vino SA.
        <br />
        <br />
        <strong>4. Data retention and protection</strong>
        <br />
        Personal Data is processed and stored for a period of 14 (fourteen)
        days, in accordance with the Swiss Directive for the containment of
        COVID-19. After this period the tracking program automatically erases
        the data.
        <br />
        <br />
        We retain and protect the transmission of your Personal Data and
        subsequently also their storage with appropriate technical
        organizational measures - where necessary also with the use of
        encryption. <br />
        <br />
        <strong>5. Cookies</strong>
        <br />
        Our APP uses exclusively a technical cookie that temporarily stores on
        your device a copy of your Personal Data - which you provide on a
        voluntary basis - for the sole purpose of facilitating the completion of
        the online tracking form for subsequent uses.
        <br />
        <br />
        <strong>6 .Legal references</strong>
        <br />
        The Privacy & Cookie Policy of Bottegone del Vino SA is drafted on the
        basis of multiple legislative systems, including articles. 13 and 14 of
        EU Regulation 2016/679 and concerns exclusively our tracking APP.
        <br />
        <br />
        For further information, please contact us by email at
        bottegonelugano@gmail.com or by mail at Bottegone del Vino SA - Via
        Magatti 3 - 6900 Lugano, Ticino (Switzerland).
      </div>
    </div>
  );
};

export default PrivacyEN;
