import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
const StyledTextField = styled(TextField)`
  label {
    color: #000000;
    opacity: 1;
  }
  label.focused {
    color: #000000;
    opacity: 1;
  }
  .MuiInputBase-input {
    color: #000000;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 0.6;
      color: #000000;
    }
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.23) !important;
      opacity: 1;
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 23) !important;
      color: #000000;
      opacity: 1;
    }
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: #000000;
      opacity: 1;
    }
  }
`;

class Report extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [], ready: false, logged: false, loggedError: false };
  }

  exportJson = () => {
    function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], { type: contentType });
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
    }

    var rows = document.querySelectorAll("table#reportTable tr");
    // Construct csv
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(";"));
    }
    var csv_string = csv.join("\n");

    download(csv_string, "report.csv", "text/csv");
  };

  async handleSubmit(e) {
    e.preventDefault();
    var that = this;
    fetch("https://bottegone.outsafe.ch/admin/api/cockpit/authUser", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Cockpit-Token": "56f08bbfcda07acb8c76bcc585625c"
      },
      body: JSON.stringify({
        user: document.getElementById("username").value,
        password: document.getElementById("password").value
      })
    })
      .then(user => user.json())
      .then(async user => {
        if (user.error) {
          this.setState({
            loggedError: true
          });
        } else {
          this.setState({
            logged: true
          });

          let removeOld = await fetch(
            "https://bottegone.outsafe.ch/admin/api/collections/remove/data",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "Cockpit-Token": "56f08bbfcda07acb8c76bcc585625c"
              },
              body: JSON.stringify({
                filter: { id: { $lt: Date.now() - 1296000000 } }
              })
            }
          );

          let getEntries = await fetch(
            "https://bottegone.outsafe.ch/admin/api/collections/get/data",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                "Cockpit-Token": "56f08bbfcda07acb8c76bcc585625c"
              },
              body: JSON.stringify({})
            }
          );

          const entriesRaw = await getEntries.json();

          const entries = entriesRaw.entries.reverse();

          const dateNow = new Date().getTime();
          // var filtered = entries.filter(function(value, index, arr) {
          //   console.log(value);
          //   const datePast = value.data.Date.split("/");
          //
          //   const datePastFormatted = new Date(
          //     datePast[2],
          //     datePast[1] - 1,
          //     datePast[0]
          //   ).getTime();
          //
          //   if (datePastFormatted + 1296000000 > dateNow) {
          //     return value;
          //   }
          // });

          this.setState({ data: entries, ready: true });
        }
      });
  }

  async componentDidMount() {}

  render() {
    return (
      <div id="reportContainer">
        {this.state.logged ? (
          <div>
            <div className="reportHeader">
              <h1>Report</h1>
              <button
                id="export"
                onClick={() => {
                  this.exportJson();
                }}
              >
                export
              </button>
            </div>
            <br />
            <br />
            <table id="reportTable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Action</th>
                  <th>Table</th>
                  <th>Name</th>
                  <th>Last Name</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>CAP</th>
                </tr>
              </thead>
              <tbody>
                {this.state.ready
                  ? this.state.data.map((item, index) => {
                      return item.json["Data"].map((value, index2) => {
                        return (
                          <tr key={index2}>
                            <td>{item.json["Date"]}</td>
                            <td>{item.json["Time"]}</td>
                            <td>{item.json["Action"]}</td>
                            <td>{item.json["Table"]}</td>
                            {Object.entries(value).map(
                              ([key2, value2], index3) => {
                                return <td key={index3}>{value2}</td>;
                              }
                            )}
                          </tr>
                        );
                      });
                    })
                  : "loading..."}
              </tbody>
            </table>
          </div>
        ) : (
          <form
            className="login"
            onSubmit={e => {
              this.handleSubmit(e);
            }}
          >
            <h1>Bottegone del Vino</h1>
            <br />
            {this.state.loggedError ? (
              <div className="loggedError">Wrong username or password</div>
            ) : null}

            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              id="username"
              name="username"
              label="Username"
              margin="normal"
              variant="outlined"
            />
            <StyledTextField
              style={{ width: "100%", marginBottom: "16px" }}
              required={true}
              type="password"
              id="password"
              name="password"
              label="Password"
              margin="normal"
              variant="outlined"
            />
            <br />
            <br />
            <Button id="send" type="submit" variant="contained">
              LOGIN
            </Button>
          </form>
        )}
      </div>
    );
  }
}

export default Report;
