import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LanguageIcon from "@material-ui/icons/Language";
import PrivacyEN from "./PrivacyEN";
import PrivacyIT from "./PrivacyIT";
import PrivacyDE from "./PrivacyDE";

const StyledTextField = styled(TextField)`
  label {
    color: #ffffff;
    opacity: 1;
  }
  label.focused {
    color: #ffffff;
    opacity: 1;
  }
  .MuiInputBase-input {
    color: #ffffff;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: rgba(255, 255, 255, 0.23) !important;
      opacity: 0.6;
      color: #ffffff;
    }
    &:hover fieldset {
      border-color: rgba(255, 255, 255, 0.23) !important;
      opacity: 1;
    }
    &.Mui-focused fieldset {
      border-color: rgba(255, 255, 255, 23) !important;
      color: #ffffff;
      opacity: 1;
    }
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: #ffffff;
      opacity: 1;
    }
  }
`;

const localStorageFirstClient = JSON.parse(
  localStorage.getItem("outsafe-bottegone")
);
var firstClient = {};
//in  putProps={{ pattern: "[A-Za-z]{2}[0-9]{2,3}" }}
function TablecodeField(props) {
  return (
    <StyledTextField
      style={{ width: "100%", marginBottom: "16px" }}
      required={true}
      id="Table"
      name="Table"
      label={
        props.lang === "en"
          ? "Table"
          : props.lang === "it"
          ? "Tavolo"
          : props.lang === "de"
          ? "Tabelle"
          : null
      }
      margin="normal"
      variant="outlined"
      className="tablecodeField"
      defaultValue={
        localStorageFirstClient ? localStorageFirstClient["Table"] : ""
      }
    />
  );
}

function SafeGroup(props) {
  return (
    <div className="safeGroup" key={props.length}>
      {props.length !== 0 ? (
        <span
          className="removesafeGroup"
          onClick={e => {
            e.target.closest(".safeGroup").remove();
          }}
        >
          <CloseIcon />
        </span>
      ) : null}
      <StyledTextField
        style={{ width: "100%" }}
        required={true}
        id={`Name${props.length}`}
        name={`Name`}
        label={
          props.lang === "en"
            ? "Name"
            : props.lang === "it"
            ? "Nome"
            : props.lang === "de"
            ? "Name"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["Name"]
            : ""
        }
      />
      <StyledTextField
        style={{ width: "100%" }}
        required={true}
        id={`Lastname${props.length}`}
        name={`Lastname`}
        label={
          props.lang === "en"
            ? "Last Name"
            : props.lang === "it"
            ? "Cognome"
            : props.lang === "de"
            ? "Nachname"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["Lastname"]
            : ""
        }
      />
      <StyledTextField
        style={{ width: "100%" }}
        required={true}
        id={`Phone${props.length}`}
        name={`Phone`}
        label={
          props.lang === "en"
            ? "Phone number"
            : props.lang === "it"
            ? "Telefono"
            : props.lang === "de"
            ? "Telefonnummer"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["Phone"]
            : ""
        }
      />
      <StyledTextField
        style={{ width: "100%" }}
        required={true}
        id={`Address${props.length}`}
        name={`Address`}
        label={
          props.lang === "en"
            ? "Address"
            : props.lang === "it"
            ? "Indirizzo"
            : props.lang === "de"
            ? "Adresse"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["Address"]
            : ""
        }
      />
      <StyledTextField
        style={{ width: "50%" }}
        required={true}
        id={`City${props.length}`}
        name={`City`}
        label={
          props.lang === "en"
            ? "City"
            : props.lang === "it"
            ? "Città"
            : props.lang === "de"
            ? "Ort"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["City"]
            : ""
        }
      />
      <StyledTextField
        style={{ width: "50%" }}
        required={true}
        id={`CAP${props.length}`}
        name={`CAP`}
        label={
          props.lang === "en"
            ? "CAP"
            : props.lang === "it"
            ? "CAP"
            : props.lang === "de"
            ? "CAP"
            : null
        }
        margin="normal"
        variant="outlined"
        className="safeGroupField"
        defaultValue={
          localStorageFirstClient && props.isfirst
            ? localStorageFirstClient["CAP"]
            : ""
        }
      />
    </div>
  );
}

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      safeGroupLength: null,
      loading: false,
      success: false,
      privacyVisible: false,
      lang: "en"
    };
  }

  async handleSubmit(e) {
    e.preventDefault();

    var dateObj = new Date();
    var year = dateObj.getFullYear();
    var month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    var day = dateObj
      .getDate()
      .toString()
      .padStart(2, "0");
    var hour = dateObj
      .getHours()
      .toString()
      .padStart(2, "0");
    var minute = dateObj
      .getMinutes()
      .toString()
      .padStart(2, "0");

    this.mailObj["Date"] = day + "/" + month + "/" + year;
    this.mailObj["Time"] = hour + ":" + minute;

    const tablecodeField = document.querySelectorAll(".tablecodeField");

    for (var t = 0; t < tablecodeField.length; t++) {
      this.mailObj["Table"] = tablecodeField[t].querySelectorAll(
        "input"
      )[0].value;
      firstClient["Table"] = tablecodeField[t].querySelectorAll(
        "input"
      )[0].value;
    }

    const safeGroupsObj = document.querySelectorAll(".safeGroup");
    this.mailObj["Data"] = [];
    for (var i = 0; i < safeGroupsObj.length; i++) {
      const safeGroupFields = safeGroupsObj[i].querySelectorAll(
        ".safeGroupField"
      );
      this.mailObj["Data"][i] = {};

      for (var j = 0; j < safeGroupFields.length; j++) {
        const safeGroupFieldsInputs = safeGroupFields[j].querySelectorAll(
          "input"
        );

        for (var y = 0; y < safeGroupFieldsInputs.length; y++) {
          this.mailObj["Data"][i][
            safeGroupFieldsInputs[y].getAttribute("name")
          ] = safeGroupFieldsInputs[y].value;

          if (i === 0) {
            firstClient[safeGroupFieldsInputs[y].getAttribute("name")] =
              safeGroupFieldsInputs[y].value;
          }
        }
      }
    }

    localStorage.setItem("outsafe-bottegone", JSON.stringify(firstClient));

    console.log(this.mailObj);

    // return;

    this.setState({
      loading: true
    });

    let sendMail = await fetch(
      "https://bottegone.outsafe.ch/admin/api/collections/save/data",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "56f08bbfcda07acb8c76bcc585625c"
        },
        body: JSON.stringify({
          data: { id: Date.now(), json: this.mailObj },
          simple: 1
        })
      }
    );

    let removeOld = await fetch(
      "https://bottegone.outsafe.ch/admin/api/collections/remove/data",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Cockpit-Token": "56f08bbfcda07acb8c76bcc585625c"
        },
        body: JSON.stringify({
          filter: { id: { $lt: Date.now() - 1296000000 } },
          simple: 1
        })
      }
    );

    if (!sendMail.ok) {
      // this.setState({
      //   loading: false,
      //   submitted: true,
      //   error: true
      console.log("error", sendMail);

      return;
    }

    document.getElementById("outsafeContainer").style.background = "#bada55";
    this.setState({
      loading: false,
      success: true
    });
    // this.setState({
    //   loading: false,
    //   submitted: true,
    //   success: true
    // });
    //
    // this.mailObj = {};

    window.scroll(0, 0);
  }

  async componentDidMount() {
    this.mailObj = {};
    this.safeGroups = [];

    if (
      (window.location.search &&
        window.location.search.indexOf("lang=it") !== -1) ||
      !window.location.search
    ) {
      this.setState({
        lang: "it"
      });
      this.safeGroups.push(
        <SafeGroup length={this.safeGroups.length} isfirst={true} lang={"it"} />
      );
    }
    if (
      window.location.search &&
      window.location.search.indexOf("lang=en") !== -1
    ) {
      this.setState({
        lang: "en"
      });
      this.safeGroups.push(
        <SafeGroup length={this.safeGroups.length} isfirst={true} lang={"en"} />
      );
    }
    if (
      window.location.search &&
      window.location.search.indexOf("lang=de") !== -1
    ) {
      this.setState({
        lang: "de"
      });
      this.safeGroups.push(
        <SafeGroup length={this.safeGroups.length} isfirst={true} lang={"de"} />
      );
    }

    this.setState({
      safeGroupLength: this.safeGroups.length
    });
  }

  setPrivacyVisible = () => {
    document.body.style.overflow = "hidden";
    this.setState({
      privacyVisible: true
    });
  };

  setPrivacyHidden = () => {
    document.body.style.overflow = "";
    this.setState({
      privacyVisible: false
    });
  };

  render() {
    return (
      <div id="outsafeContainer">
        {this.state.loading ? <div className="outsafeLoader" /> : null}
        {this.state.success ? (
          <div className="outsafeInner success">
            <span className="svg">
              <CheckCircleIcon />
            </span>
            <br />
            {this.mailObj["Action"] === "EXIT" ? (
              <p style={{ textAlign: "center" }}>
                <strong style={{ fontSize: "3.6rem" }}>
                  {this.state.lang === "en"
                    ? "EXIT"
                    : this.state.lang === "it"
                    ? "USCITA"
                    : this.state.lang === "de"
                    ? "EXIT"
                    : null}
                </strong>
              </p>
            ) : null}

            {this.mailObj["Action"] === "ENTER" ? (
              <p style={{ textAlign: "center" }}>
                <strong style={{ fontSize: "3.6rem" }}>
                  {this.state.lang === "en"
                    ? "ENTER"
                    : this.state.lang === "it"
                    ? "ENTRATA"
                    : this.state.lang === "de"
                    ? "ENTER"
                    : null}
                </strong>
              </p>
            ) : null}

            <p style={{ textAlign: "center" }}>
              <strong style={{ fontSize: "1.6rem" }}>
                {this.state.lang === "en"
                  ? "Thank you for helping us keep everyone safe"
                  : this.state.lang === "it"
                  ? "Grazie per aver contribuito alla sicurezza di tutti."
                  : this.state.lang === "de"
                  ? "Vielen Dank"
                  : null}
              </strong>
            </p>

            <table className="recapTable">
              <tr>
                <td>Action</td>
                <td>{this.mailObj["Action"]}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{this.mailObj["Date"]}</td>
              </tr>
              <tr>
                <td>Time</td>
                <td>{this.mailObj["Time"]}</td>
              </tr>
              <tr>
                <td>Table</td>
                <td>{this.mailObj["Table"]}</td>
              </tr>
            </table>

            {this.mailObj["Data"].map((value, index) => {
              return (
                <table className="recapTable">
                  {Object.entries(value).map(([key2, value2], index) => {
                    return (
                      <tr>
                        <td>{key2}</td>
                        <td>{value2}</td>
                      </tr>
                    );
                  })}
                </table>
              );
            })}

            <br />
            <p>
              {this.state.lang === "en"
                ? "The data will be treated confidentially.<br/>The cantonal medical office can request contact details if it deems it necessary."
                : this.state.lang === "it"
                ? "I dati verranno trattati confidenzialmente. L’ufficio medico cantonale può richiedere i dati di contatto qualora lo ritenga necessario."
                : this.state.lang === "de"
                ? "Die Kontaktdaten werden vertraulich behandelt. Das Bundesamt für Gesundheit kann diese anfordern sollte es dies für nötig halten."
                : null}
            </p>
          </div>
        ) : (
          <div className="outsafeInner">
            <img
              style={{ display: "none" }}
              className="pop"
              src={require("./assets/popsiclewhite.svg")}
            />
            <div className="langSelector">
              <LanguageIcon />
              <a href="?lang=it">it</a>
              <a href="?lang=de">de</a>
              <a href="?lang=en">en</a>
            </div>
            <h1>Bottegone del Vino</h1>

            <br />
            <p>
              {this.state.lang === "en"
                ? "The data will be treated confidentially. The cantonal medical office can request contact details if it deems it necessary."
                : this.state.lang === "it"
                ? "I dati verranno trattati confidenzialmente. L’ufficio medico cantonale può richiedere i dati di contatto qualora lo ritenga necessario."
                : this.state.lang === "de"
                ? "Die Kontaktdaten werden vertraulich behandelt. Das Bundesamt für Gesundheit kann diese anfordern sollte es dies für nötig halten."
                : null}
            </p>
            <form
              onSubmit={e => {
                this.handleSubmit(e);
              }}
            >
              {TablecodeField({ lang: this.state.lang })}
              {this.safeGroups
                ? this.safeGroups.map((group, index) => {
                    return group;
                  })
                : null}
              <Button
                id="addGuest"
                variant="outlined"
                onClick={() => {
                  this.safeGroups.push(
                    <SafeGroup
                      length={this.safeGroups.length}
                      lang={this.state.lang}
                    />
                  );

                  this.setState({
                    safeGroupLength: this.safeGroups.length
                  });
                }}
              >
                {this.state.lang === "en"
                  ? "+ ADD GUEST"
                  : this.state.lang === "it"
                  ? "+ AGGIUNGI OSPITE"
                  : this.state.lang === "de"
                  ? "+ GAST HINZUFÜNGEN"
                  : null}
              </Button>
              <br />

              <br />
              <Button
                id="send"
                type="submit"
                variant="contained"
                onClick={() => {
                  this.mailObj["Action"] = "ENTER";
                }}
              >
                {this.state.lang === "en"
                  ? "ENTER"
                  : this.state.lang === "it"
                  ? "ENTRA"
                  : this.state.lang === "de"
                  ? "ENTER"
                  : null}
              </Button>
              <br />
              <br />
              <Button
                id="send"
                type="submit"
                variant="contained"
                onClick={() => {
                  this.mailObj["Action"] = "EXIT";
                }}
              >
                {this.state.lang === "en"
                  ? "EXIT"
                  : this.state.lang === "it"
                  ? "ESCI"
                  : this.state.lang === "de"
                  ? "EXIT"
                  : null}
              </Button>

              <br />
              <br />

              {this.state.lang === "en" ? (
                <p>
                  This APP uses a technical cookie that temporarily saves a copy
                  of your data on your device. You declare that you accept the
                  use of this cookie by completing the registration.
                  <br />
                  <br />
                  For more information see our{" "}
                  <a
                    onClick={() => this.setPrivacyVisible()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    Privacy & Cookie Policy
                  </a>
                  .
                </p>
              ) : this.state.lang === "it" ? (
                <p>
                  Questa APP utilizza un cookie tecnico che salva
                  temporaneamente sul tuo dispositivo una copia dei tuoi dati.
                  Dichiari di accettare l'utilizzo del cookie continuando con la
                  registrazione.
                  <br />
                  <br />
                  Per maggiori informazioni consulta la nostra{" "}
                  <a
                    onClick={() => this.setPrivacyVisible()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    Privacy & Cookie Policy
                  </a>
                  .
                </p>
              ) : this.state.lang === "de" ? (
                <p>
                  This APP uses a technical cookie that temporarily saves a copy
                  of your data on your device. You declare that you accept the
                  use of this cookie by completing the registration.
                  <br />
                  <br />
                  For more information see our{" "}
                  <a
                    onClick={() => this.setPrivacyVisible()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    Privacy & Cookie Policy
                  </a>
                  .
                </p>
              ) : null}

              {this.state.privacyVisible && this.state.lang === "en" ? (
                <>
                  <a
                    className="closePrivacy"
                    onClick={() => this.setPrivacyHidden()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    <CloseIcon />
                  </a>
                  <PrivacyEN />
                </>
              ) : null}
              {this.state.privacyVisible && this.state.lang === "de" ? (
                <>
                  <a
                    className="closePrivacy"
                    onClick={() => this.setPrivacyHidden()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    <CloseIcon />
                  </a>
                  <PrivacyDE />
                </>
              ) : null}
              {this.state.privacyVisible && this.state.lang === "it" ? (
                <>
                  <a
                    className="closePrivacy"
                    onClick={() => this.setPrivacyHidden()}
                    href="#"
                    style={{ color: "inherit" }}
                  >
                    <CloseIcon />
                  </a>
                  <PrivacyIT />
                </>
              ) : null}
            </form>
          </div>
        )}
        <div className="privateArea">
          <a href="/report">Private Area</a>
        </div>
      </div>
    );
  }
}

export default Form;
